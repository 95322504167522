<template>
  <div class="faq_list">
    <div class="container">
      <!-- <h3 class="title">Frequently Asked Questions</h3> -->
      <img src="@/assets/images/loanImg/icon_faq.png" alt="">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item v-for="(item, index) in faqList" :id="item.id" :key="index" :title="item.title" :name="item.name">
          <div v-html="item.dec" />
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ['1'],
      faqList: [
        {
          name: '1',
          id: 'question-1',
          title: '1. ¿Qué es EasyKash Vip?—Préstamo instantáneo personal ',
          dec: 'Este es un enfoque simple e ideal para el financiamiento personal que puede ayudarlo a cubrir los costos inmediatos de su vida:Pagar unamulta o factura accidental Reparación de nuestro vehículo o fallo del equipo Pague sus propias facturas médicas o de mascotas Comprar regalos de cumpleaños sorpresa Paga por cursos que te hacen bueno Pagar un curso para mejorar más su capacidad '
        },
        {
          name: '2',
          id: 'question-2',
          title: '2.¿Cuál es la forma de reembolsar el préstamo',
          dec: '1. Pago con Baloto </br> 2. Corresponsales de Bancolombia </br> 3. Su red - payValida </br> 4. PUNTO ROJO - Bancupo </br> 5. Pague en línea a través de PSE, seleccione Pagar y realice pagos virtuales.'
        },
        {
          name: '3',
          id: 'question-3',
          title: '3.¿Cómo puedo ver las políticas de privacidad?',
          dec: '<a href="/about_us?type=1">Haga clic</a> para ver nuestra política de privacidad.'
        },
        {
          name: '4',
          id: 'question-4',
          title: '4. ¿Qué debo hacer si se rechaza la solicitud?',
          dec: 'Nuestro sistema totalmente automático tiene en cuenta muchos factores de análisis de perfiles de riesgo. Si su solicitud es lamentablemente rechazada, usted puede tratar de solicitar un préstamo en 30 días, y vamos a tratar de ayudarle.'
        },
        {
          name: '5',
          id: 'question-5',
          title: '5. ¿Puedo solicitar una prórroga del préstamo?',
          dec: 'Sí, si el monto total del préstamo no puede ser pagado en la fecha de re-compromiso, el plazo puede ser solicitado con la ayuda del asesor de servicio y le cobraremos intereses de acuerdo con la ley colombiana. Pero si usted no maneja su reembolso activamente, cobraremos daños y perjuicios liquidados al más alto nivel legal y arreglaremos que los abogados profesionales se ocupen de su incumplimiento de contrato.'
        },
        {
          name: '6',
          id: 'question-6',
          title: '6. ¿Dónde puedo ver el préstamo?',
          dec: 'Pronto enviaremos su préstamo a la tarjeta bancaria que dejó al solicitar el préstamo, y el dinero llegará a su tarjeta bancaria para su uso.'
        },
        {
          name: '7',
          id: 'question-7',
          title: '7. Habré reembolsado el préstamo. ¿Cuándo puedo obtener otro préstamo?',
          dec: 'Usted puede volver a solicitar el préstamo el día después de pagar y mantener su crédito. Le daremos un límite de préstamo más alto para que pueda manejar gastos repentinos difíciles.'
        },
        {
          name: '8',
          id: 'question-8',
          title: '8. Si tengo alguna pregunta, ¿cómo debo contactar con usted?',
          dec: 'No se preocupe si puede ponerse en contacto con un consultor de servicio profesional para preguntas de reembolso, ya que el consultor de servicio se pondrá en contacto con usted de forma proactiva al recibir la tarea. Cooperación empresarial Por favor, póngase en contacto con el buzón de correo info@dragonfly-sas.com Teléfono 3187171958'
        },
        {
          name: '9',
          id: 'question-9',
          title: '9. ¿Qué información se requiere para solicitar un préstamo?',
          dec: 'Como ciudadano colombiano </br> Alcanzar la mayoría de edad y residir en Colombia </br> Posesión de cuentas bancarias legales en Colombia </br> Tenga su propio correo electrónico </br> Tener un teléfono móvil que pueda recibir mensajes de texto y un número propio'
        },
        {
          name: '10',
          id: 'question-10',
          title: '10. ¿Cuánto tiempo puedo obtener el dinero después de solicitar un préstamo?',
          dec: 'platasube Su préstamo de crédito será pagado en su cuenta bancaria en menos de 24 horas de trabajo, con un tiempo máximo de 5 minutos.'
        },
        {
          name: '11',
          id: 'question-11',
          title: '11. ¿Puedo saber el contrato de préstamo de platasube por adelantado?',
          dec: 'Sí, haga clic aquí para ver <a href="/about_us?type=3">《Contrato de préstamos》</a>'
        },
        {
          name: '12',
          id: 'question-12',
          title: '12. ¿Cómo puedo aumentar mi crédito?',
          dec: 'La línea de crédito es generada automáticamente por el sistema, manteniendo un excelente y oportuno reembolso, lo que le permite obtener más dinero la próxima vez que pida prestado.'
        }
      ]
    }
  },
  methods: {
    handleChange(val) {
      console.log(val)
    }
  }

}
</script>

<style lang="scss" scoped>
.faq_list{
  min-width: 1200px;
  padding: 50px calc((100% - 1100px) / 2) 50px;
  margin: 0 auto;
  img{
    display: block;
    margin: 0 auto;
    width: 350px;
    height: 300px;
  }
  .title{
    font-size: 30px;
    color: #333333;
    margin-bottom: 40px;
  }
}
:deep(.el-collapse-item__header) {
  background: #EEEEEE;
  padding-left: 20px;
}
:deep(.el-collapse-item__content) {
  padding: 20px 480px 50px 20px;
}
@media only screen and (max-width: 665px){
  .faq_list{
    min-width: calc(100vw);
    padding: 0 !important;
    .container{
      img{
        width: 250px;
        height: 200px;
        margin: 10px auto;
      }
    }
  }
  :deep(.el-collapse-item__header) {
    background: #EEEEEE;
    padding-left: 20px;
    font-size: 12px !important;
    line-height: 24px !important;
    height: 50px !important;
  }
  :deep(.el-collapse-item__content) {
    padding: 20px 20px !important;
    font-size: 12px !important;
  }
}
</style>
